import { useMutation } from '@apollo/client';
import { LabelConfig } from '@tactiq/model';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../../components/buttons';
import { archiveMeeting } from '../../../graphql/meetings';
import {
  DeleteMeetingDocument,
  MeetingPlatform,
  Tag,
} from '../../../graphql/operations';
import { hideWord } from '../../../helpers/meetings';
import iconWebex from '../../../img/webex.png';
import { AnyMeeting } from '../../../models/meeting';
import { selectIsHideNamesEnabled } from '../../../redux/selectors';
import { AddLabelButton } from '../../Common/AddLabelButton';
import { Label, LabelWrapper } from '../../Common/Label';
import { MeetingActions } from './MeetingActions';
import { CardMeetingParticipants } from './MeetingParticipants';
import { isCurrent } from './helpers';
import { trackWebEvent } from '../../../helpers/analytics';
import {
  AlertTriangle,
  FileTypeIcon,
  FileVideo,
  FileVolume,
  User2,
  XOctagon,
} from 'lucide-react';
import { cx } from '../../../helpers/utils';
import { deleteMeeting as deleteMeetingAction } from '../../../redux/modules/global';
import { Tooltip } from '../../../components/Tooltip';
import { Spinner } from '../../../components/Spinner';
import { useSearchQueryState } from '../../../services/Search';

const ItemRowClasses =
  'flex flex-col item-center border border-slate-200 border-solid first:rounded-se-card first:rounded-ss-card last:rounded-ee-card last:rounded-es-card last:rounded-ss-card-none last:rounded-se-card-none -mt-[1px] first:mt-0 hover:bg-slate-50/25';

const ItemRowMainContentClasses =
  'flex-1 flex-col w-full md:pr-0 pr-2 py-1 overflow-x-hidden';

const ItemRowPhotoClasses =
  'w-[60px] [&>img]:w-9 [&>img]:h-9 [&>img]:rounded-full';

const linkStyleClasses = 'hover:brightness-[0.98] cursor-pointer';

// 3600 sec / hour * 1000 milis / sec * 2 hours;
export const UPLOAD_MAX_PROCESSING_TIME_MS = 3600 * 1000 * 2;

const MeetingCardContentClasses = 'flex items-center w-full py-1';

const MeetingCardLabelsClasses =
  'flex flex-wrap flew-row w-full gap-2 my-1 content-start items-center';

const MeetingTitleWrapperClasses =
  'flex flex-row items-center h-5 *:whitespace-nowrap *:w-full *:overflow-hidden *:text-ellipsis';

const MeetingDurationClasses =
  'flex w-[60px] text-xl flex-col justify-center items-center cursor-default';

const MeetingPlatformIconClasses = 'h-4 w-4';

const MeetingPlatformGoogleMeet = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 87.5 72"
    height="16px"
    width="16px"
  >
    <path
      fill="#00832d"
      d="M49.5 36l8.53 9.75 11.47 7.33 2-17.02-2-16.64-11.69 6.44z"
    />
    <path
      fill="#0066da"
      d="M0 51.5V66c0 3.315 2.685 6 6 6h14.5l3-10.96-3-9.54-9.95-3z"
    />
    <path fill="#e94235" d="M20.5 0L0 20.5l10.55 3 9.95-3 2.95-9.41z" />
    <path fill="#2684fc" d="M20.5 20.5H0v31h20.5z" />
    <path
      fill="#00ac47"
      d="M82.6 8.68L69.5 19.42v33.66l13.16 10.79c1.97 1.54 4.85.135 4.85-2.37V11c0-2.535-2.945-3.925-4.91-2.32zM49.5 36v15.5h-29V72h43c3.315 0 6-2.685 6-6V53.08z"
    />
    <path
      fill="#ffba00"
      d="M63.5 0h-43v20.5h29V36l20-16.57V6c0-3.315-2.685-6-6-6z"
    />
  </svg>
);

const MeetingPlatformZoom = (
  <svg
    fill="none"
    height="16px"
    width="16px"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 50.667 50.667"
  >
    <path
      d="M25.333 50.667c13.992 0 25.334-11.343 25.334-25.334S39.325 0 25.333 0 0 11.342 0 25.333s11.342 25.334 25.333 25.334z"
      fill="#2196f3"
    />
    <path
      clipRule="evenodd"
      d="M14.866 32.574h16.755V20.288c0-1.851-1.5-3.351-3.351-3.351H11.515v12.286c0 1.851 1.5 3.351 3.351 3.351zm18.988-4.467l6.702 4.467V16.937l-6.701 4.468z"
      fill="#fff"
      fillRule="evenodd"
    />
  </svg>
);

const MeetingPlatformMSTeams = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 2228.833 2073.333"
    height="16px"
    width="16px"
  >
    <path
      fill="#5059C9"
      d="M1554.637,777.5h575.713c54.391,0,98.483,44.092,98.483,98.483c0,0,0,0,0,0v524.398	c0,199.901-162.051,361.952-361.952,361.952h0h-1.711c-199.901,0.028-361.975-162-362.004-361.901c0-0.017,0-0.034,0-0.052V828.971	C1503.167,800.544,1526.211,777.5,1554.637,777.5L1554.637,777.5z"
    />
    <circle fill="#5059C9" cx="1943.75" cy="440.583" r="233.25" />
    <circle fill="#7B83EB" cx="1218.083" cy="336.917" r="336.917" />
    <path
      fill="#7B83EB"
      d="M1667.323,777.5H717.01c-53.743,1.33-96.257,45.931-95.01,99.676v598.105	c-7.505,322.519,247.657,590.16,570.167,598.053c322.51-7.893,577.671-275.534,570.167-598.053V877.176	C1763.579,823.431,1721.066,778.83,1667.323,777.5z"
    />
    <path
      opacity=".1"
      d="M1244,777.5v838.145c-0.258,38.435-23.549,72.964-59.09,87.598	c-11.316,4.787-23.478,7.254-35.765,7.257H667.613c-6.738-17.105-12.958-34.21-18.142-51.833	c-18.144-59.477-27.402-121.307-27.472-183.49V877.02c-1.246-53.659,41.198-98.19,94.855-99.52H1244z"
    />
    <path
      opacity=".2"
      d="M1192.167,777.5v889.978c-0.002,12.287-2.47,24.449-7.257,35.765	c-14.634,35.541-49.163,58.833-87.598,59.09H691.975c-8.812-17.105-17.105-34.21-24.362-51.833	c-7.257-17.623-12.958-34.21-18.142-51.833c-18.144-59.476-27.402-121.307-27.472-183.49V877.02	c-1.246-53.659,41.198-98.19,94.855-99.52H1192.167z"
    />
    <path
      opacity=".2"
      d="M1192.167,777.5v786.312c-0.395,52.223-42.632,94.46-94.855,94.855h-447.84	c-18.144-59.476-27.402-121.307-27.472-183.49V877.02c-1.246-53.659,41.198-98.19,94.855-99.52H1192.167z"
    />
    <path
      opacity=".2"
      d="M1140.333,777.5v786.312c-0.395,52.223-42.632,94.46-94.855,94.855H649.472	c-18.144-59.476-27.402-121.307-27.472-183.49V877.02c-1.246-53.659,41.198-98.19,94.855-99.52H1140.333z"
    />
    <path
      opacity=".1"
      d="M1244,509.522v163.275c-8.812,0.518-17.105,1.037-25.917,1.037	c-8.812,0-17.105-0.518-25.917-1.037c-17.496-1.161-34.848-3.937-51.833-8.293c-104.963-24.857-191.679-98.469-233.25-198.003	c-7.153-16.715-12.706-34.071-16.587-51.833h258.648C1201.449,414.866,1243.801,457.217,1244,509.522z"
    />
    <path
      opacity=".2"
      d="M1192.167,561.355v111.442c-17.496-1.161-34.848-3.937-51.833-8.293	c-104.963-24.857-191.679-98.469-233.25-198.003h190.228C1149.616,466.699,1191.968,509.051,1192.167,561.355z"
    />
    <path
      opacity=".2"
      d="M1192.167,561.355v111.442c-17.496-1.161-34.848-3.937-51.833-8.293	c-104.963-24.857-191.679-98.469-233.25-198.003h190.228C1149.616,466.699,1191.968,509.051,1192.167,561.355z"
    />
    <path
      opacity=".2"
      d="M1140.333,561.355v103.148c-104.963-24.857-191.679-98.469-233.25-198.003	h138.395C1097.783,466.699,1140.134,509.051,1140.333,561.355z"
    />
    <linearGradient
      id="a"
      gradientUnits="userSpaceOnUse"
      x1="198.099"
      y1="1683.0726"
      x2="942.2344"
      y2="394.2607"
      gradientTransform="matrix(1 0 0 -1 0 2075.3333)"
    >
      <stop offset="0" stopColor="#5a62c3" />
      <stop offset=".5" stopColor="#4d55bd" />
      <stop offset="1" stopColor="#3940ab" />
    </linearGradient>
    <path
      fill="url(#a)"
      d="M95.01,466.5h950.312c52.473,0,95.01,42.538,95.01,95.01v950.312c0,52.473-42.538,95.01-95.01,95.01	H95.01c-52.473,0-95.01-42.538-95.01-95.01V561.51C0,509.038,42.538,466.5,95.01,466.5z"
    />
    <path
      fill="#FFF"
      d="M820.211,828.193H630.241v517.297H509.211V828.193H320.123V727.844h500.088V828.193z"
    />
  </svg>
);

export interface MeetingCardProps {
  meeting: AnyMeeting;
  isLink?: boolean;
  readOnly?: boolean;
  isPaidUser: boolean;
  isOwner: boolean;
  userTags: Tag[];
  onRemoveLabel?: (label: LabelConfig) => void;
  onClick?: () => void;
}

/**
 *
 * @param {MeetingCardProps} props props
 * @returns {React.ReactNode} Meeting Card Component
 */
export const MeetingCardImpl: React.FC<MeetingCardProps> = (props) => {
  const dispatch = useDispatch();
  const {
    meeting,
    isLink = true,
    readOnly = false,
    isPaidUser,
    isOwner,
    onRemoveLabel,
    onClick,
  } = props;
  const isPreview = meeting.isPreview ?? false;
  const isArchived = !!meeting.archivedAt;
  const participants = meeting.participants ?? [];
  const [, setFilter] = useSearchQueryState();

  const hadProcessingError =
    meeting.hadProcessingError ||
    (meeting.isUploading &&
      meeting.modified < Date.now() - UPLOAD_MAX_PROCESSING_TIME_MS);

  const hideNames = useSelector(selectIsHideNamesEnabled);

  const intl = useIntl();

  const [deleteMeeting, deleteMeetingMutaiton] = useMutation(
    DeleteMeetingDocument
  );

  const isActiveUpload =
    typeof meeting.uploadProgress !== 'undefined' &&
    meeting.uploadProgress >= 0 &&
    meeting.uploadProgress < 100;
  const tags = meeting.tags;

  let actions = null;

  if (!readOnly && !isActiveUpload) {
    actions = <MeetingActions meeting={meeting} fullSize={false} />;
  }

  let platform = null;
  switch (meeting.platform) {
    case MeetingPlatform.GOOGLE_MEET:
      platform = (
        <Tooltip
          placement="top"
          title={
            <FormattedMessage
              defaultMessage="Meeting was held on Google Meet"
              id="3mWzjO"
            />
          }
        >
          <LabelWrapper>{MeetingPlatformGoogleMeet}</LabelWrapper>
        </Tooltip>
      );
      break;
    case MeetingPlatform.MS_TEAMS:
      platform = (
        <Tooltip
          placement="top"
          title={
            <FormattedMessage
              defaultMessage="Meeting was held on Microsoft Teams"
              id="541sBC"
            />
          }
        >
          <LabelWrapper>{MeetingPlatformMSTeams}</LabelWrapper>
        </Tooltip>
      );
      break;
    case MeetingPlatform.WEBEX_API:
      platform = (
        <Tooltip
          placement="top"
          title={
            <FormattedMessage
              defaultMessage="Meeting was held on Webex"
              id="7+TYzm"
            />
          }
        >
          <LabelWrapper>
            {
              <img
                className={MeetingPlatformIconClasses}
                src={iconWebex}
                alt={intl.formatMessage({
                  defaultMessage: 'Webex Icon',
                  id: 'wO4VMT',
                })}
              />
            }
          </LabelWrapper>
        </Tooltip>
      );
      break;
    case MeetingPlatform.ZOOM:
    case MeetingPlatform.ZOOM_API:
    case MeetingPlatform.ZOOM_DESKTOP:
      platform = (
        <Tooltip
          placement="top"
          title={
            <FormattedMessage
              defaultMessage="Meeting was held on Zoom"
              id="5gR7fG"
            />
          }
        >
          <LabelWrapper>{MeetingPlatformZoom}</LabelWrapper>
        </Tooltip>
      );
      break;
    case MeetingPlatform.UPLOADED_TRANSCRIPT:
      platform = (
        <Tooltip
          placement="top"
          title={
            <FormattedMessage
              defaultMessage="This transcript was uploaded to Tactiq"
              id="yee3gb"
            />
          }
        >
          <LabelWrapper>
            {<FileTypeIcon className="text-indigo-600" size="16px" />}
          </LabelWrapper>
        </Tooltip>
      );
      break;
    case MeetingPlatform.UPLOADED_AUDIO:
      platform = (
        <Tooltip
          placement="top"
          title={
            <FormattedMessage
              defaultMessage="This audio was uploaded to Tactiq"
              id="zd92ie"
            />
          }
        >
          <LabelWrapper>
            {<FileVolume className="text-indigo-600" size="16px" />}
          </LabelWrapper>
        </Tooltip>
      );
      break;
    case MeetingPlatform.UPLOADED_VIDEO:
      platform = (
        <Tooltip
          placement="top"
          title={
            <FormattedMessage
              defaultMessage="This video was uploaded to Tactiq"
              id="Dgq3nb"
            />
          }
        >
          <LabelWrapper>
            {<FileVideo className="text-indigo-600" size="16px" />}
          </LabelWrapper>
        </Tooltip>
      );
      break;
    default:
      platform = null;
      break;
  }

  function meetingStatusChip() {
    if (hadProcessingError) {
      return (
        <LabelWrapper>
          <p className="text-yellow-600">
            <FormattedMessage
              defaultMessage="There was an error processing this file"
              id="rfAQCO"
            />
          </p>
        </LabelWrapper>
      );
    }

    if (isActiveUpload) {
      return (
        <LabelWrapper>
          <p className="text-indigo-600">
            <FormattedMessage
              defaultMessage="This meeting is being uploaded"
              id="yUQ1mw"
            />
          </p>
        </LabelWrapper>
      );
    }

    // note: this is a bad flag name. It actually means "transcript file is uploaded and now is processed by background task"
    if (meeting.isUploading) {
      return (
        <LabelWrapper>
          <p className="text-indigo-600">
            <FormattedMessage
              defaultMessage="This meeting is being processed"
              id="Ljh8AJ"
            />
          </p>
        </LabelWrapper>
      );
    }
  }
  const uploadingOrError = meetingStatusChip();

  function renderProcessingProgress() {
    if (meeting.isUploading && !hadProcessingError) {
      return <Spinner size="1.5rem" />;
    }
    if (
      typeof meeting.uploadProgress !== 'undefined' &&
      meeting.uploadProgress >= 0 &&
      meeting.uploadProgress < 100
    ) {
      return <Spinner size="1.5rem" />;
    }
  }

  const onMeetingCardClick = useCallback(() => {
    if (hadProcessingError) {
      return null;
    }
    if (meeting.isUploading || isActiveUpload) {
      return () =>
        enqueueSnackbar(
          intl.formatMessage({
            defaultMessage: 'The meeting has not finished processing yet',
            id: 'UeRUCB',
          }),
          { variant: 'WARNING' }
        );
    }
    return onClick ? onClick() : true;
  }, [intl, hadProcessingError, isActiveUpload, meeting.isUploading, onClick]);

  const meetingDuration = Math.floor(
    (meeting.speechDuration || meeting.duration) / 60
  );

  return (
    <a
      className={cx(
        ItemRowClasses,
        isPreview ? '#fcfcfc' : '#ffffff',
        isLink ? linkStyleClasses : ''
      )}
      {...(isLink && !isActiveUpload && !meeting.isUploading
        ? { href: `/#/transcripts/${meeting.id}` }
        : {
            onClick: onMeetingCardClick,
          })}
      onClick={onMeetingCardClick}
    >
      <div className={MeetingCardContentClasses}>
        {hadProcessingError ? (
          <div className="flex w-14 items-center justify-center">
            <AlertTriangle className="text-orange-600" />
          </div>
        ) : (
          <div className={ItemRowPhotoClasses}>
            <div className={MeetingDurationClasses}>
              {!isNaN(meetingDuration) && !meeting.isUploading ? (
                <>
                  <div>{meetingDuration}</div>
                  <div className="text-xs">min</div>
                </>
              ) : null}
            </div>
          </div>
        )}

        <div className={ItemRowMainContentClasses}>
          <span>
            <div
              className={MeetingTitleWrapperClasses}
              aria-label={meeting.title}
            >
              {hideNames ? (
                hideWord(meeting.title)
              ) : (
                <span>{meeting.title}</span>
              )}
            </div>
          </span>
          {participants?.length ? (
            <CardMeetingParticipants
              meeting={meeting}
              participants={participants}
            />
          ) : null}

          {uploadingOrError ? (
            <div className={MeetingCardLabelsClasses}>
              {platform}
              {uploadingOrError}
            </div>
          ) : (
            <div
              className={MeetingCardLabelsClasses}
              onClick={(e) => e.preventDefault()}
            >
              {platform}
              {!isPaidUser && meeting.duration < 5 * 60 ? (
                <Tooltip
                  placement="top"
                  title={
                    <FormattedMessage
                      defaultMessage="Meeting shorter than 5 minutes are free"
                      id="SrLT7P"
                      description="Free meeting tag. Tooltip text."
                    />
                  }
                >
                  <LabelWrapper>
                    <p className="text-indigo-600">
                      <FormattedMessage defaultMessage="Free" id="tf1lIh" />
                    </p>
                  </LabelWrapper>
                </Tooltip>
              ) : null}
              {isCurrent(meeting) ? (
                <Tooltip
                  placement="top"
                  title={
                    <FormattedMessage
                      defaultMessage="Processing this meeting is still in progress"
                      id="66bk1J"
                    />
                  }
                >
                  <LabelWrapper>
                    <FormattedMessage
                      defaultMessage="In Progress"
                      id="B487HA"
                    />
                  </LabelWrapper>
                </Tooltip>
              ) : null}
              {meeting.participants ? (
                <Tooltip
                  placement="top"
                  title={
                    <FormattedMessage
                      defaultMessage="Users present in the meeting"
                      id="7cbgps"
                    />
                  }
                >
                  <LabelWrapper
                    onClick={() =>
                      setFilter({
                        speakers: meeting.participants.map((ii) => ii.name),
                      })
                    }
                  >
                    <User2 className="mr-1 h-4 w-4" />
                    {meeting.participants.length}
                  </LabelWrapper>
                </Tooltip>
              ) : null}
              {meeting.hasAiOutputs && (
                <Tooltip
                  placement="top"
                  title={
                    <FormattedMessage
                      defaultMessage={`This meeting has AI-generated content`}
                      id="i3aQM0"
                    />
                  }
                >
                  <LabelWrapper>🔮</LabelWrapper>
                </Tooltip>
              )}
              {tags?.map(({ tag, count }) => {
                return (
                  <Tooltip
                    placement="top"
                    key={tag.name}
                    title={' ' + tag.name}
                  >
                    <LabelWrapper
                      onClick={() => setFilter({ tags: [tag.name] })}
                    >
                      {tag.icon} {count}
                    </LabelWrapper>
                  </Tooltip>
                );
              })}
              {meeting.screenshotsCount ? (
                <Tooltip
                  placement="top"
                  title={
                    <FormattedMessage
                      defaultMessage="Screenshots"
                      id="HAysmO"
                      description="Meeting card, screenshots count tooltip."
                    />
                  }
                >
                  <LabelWrapper>📷 {meeting.screenshotsCount}</LabelWrapper>
                </Tooltip>
              ) : null}
              {meeting?.hasRecordings ? (
                <Tooltip
                  placement="top"
                  title={
                    <FormattedMessage
                      defaultMessage="Recording"
                      id="8KFPoz"
                      description="Meeting card, recording label."
                    />
                  }
                >
                  <LabelWrapper>🎥</LabelWrapper>
                </Tooltip>
              ) : null}
              {meeting.labels?.map((l) => (
                <Label
                  key={l.id}
                  {...l}
                  onClick={() => setFilter({ labels: [l.name] })}
                  onDelete={
                    !l.isAuto && onRemoveLabel && isOwner
                      ? () => onRemoveLabel(l)
                      : undefined
                  }
                />
              ))}
              {isOwner && !isPreview && !readOnly && !isArchived && (
                <AddLabelButton
                  meetingId={meeting.id}
                  meetingLabels={meeting.labels}
                  small={true}
                />
              )}
            </div>
          )}
        </div>

        <div className="flex items-center gap-4 pr-4">
          {!meeting.isUploading && !hadProcessingError && actions && (
            <div onClick={(e) => e.preventDefault()}>{actions}</div>
          )}

          {hadProcessingError && (
            <Button
              loading={deleteMeetingMutaiton.loading}
              onClick={async () => {
                trackWebEvent('Meeting card - delete transcript with an error');
                await archiveMeeting(meeting.id);
                await deleteMeeting({
                  variables: {
                    input: {
                      id: meeting.id,
                    },
                  },
                });
                dispatch(deleteMeetingAction(meeting.id));
              }}
              variant="filled"
              color="warning"
              size="small"
              endIcon={<XOctagon className="h-6 w-6 text-white" />}
            >
              <FormattedMessage defaultMessage="Delete Upload" id="8aV66K" />
            </Button>
          )}
          {renderProcessingProgress()}
        </div>
      </div>
    </a>
  );
};
