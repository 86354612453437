export const kSignUpPage = '/signup';
export const kSignInPage = '/signin';
export const kSignInFromExtensionSuccessPage = '/signedin';
export const kWelcomeUpPage = '/welcome';
export const kEmailPasswordSignInPage = '/signin-password';
export const kForgotPasswordPage = '/password-reset';

export const kMyHubs = '/my-hubs';
export const kNewHub = '/newhub';
export const kUserSettings = '/user-settings';
export const kCreditPage = '/my-credits';
export const kBilling = '/billing';
export const kPaymentSuccess = '/payment-success';
export const kPaymentSuccessRedirect = '/#/payment-success';
export const kMeetingKits = '/meeting-kits';
export const kMeetingKitsExplore = '/meeting-kits/explore';
export const kMeetingKitsViewKit = '/meeting-kits/:kitId';
export const kMeetingKitsEditKit = '/meeting-kits/:kitId/edit';
export const kMeetingKitsViewKitItem = '/meeting-kits/:kitId/:itemId';
export const kMeetingKitsEditKitItem = '/meeting-kits/:kitId/:itemId/edit';

export const kIntegrations = '/integrations';
export const kIntegrationsRedirect = '/#/integrations';

export const kHubPage = '/hub/:hubId';
export const kHubSpace = `${kHubPage}/space`;
export const kMeetingPage = `${kHubPage}/meeting/:meetingId?/:page?`;
export const kHubSettings = `${kHubPage}/hub-settings`;

export const kMeetingNotesPiece = 'notes';
export const kMeetingAnalyticPiece = 'analytic';
export const kMeetingSettingsPiece = 'settings';

export const kExtensionLogin = '/extension-login';
export const kExtensionSignInPage = '/extension/signin';
export const kExtensionDeletePage = '/seeyousoon';
export const kExtensionDeletePageLanding = 'https://tactiq.io/seeyousoon';

export const kTeamJoin = '/team-join';

export const kHome = '/';
export const kPlans = 'https://tactiq.io/plans';
export const kContact = 'https://tactiq.typeform.com/to/i3D77b';
export const kPrivacy = 'https://tactiq.io/privacy';
export const kTerms = 'https://tactiq.io/terms';
export const kHowItWorks = 'https://tactiq.io/howitworks';

const isProduction = process.env.NODE_ENV === 'production';
const baseUrl = isProduction
  ? 'https://app.tactiq.io'
  : 'https://testfirebaseauth-f5df6.firebaseapp.com';
export const kWebSignIn = `${baseUrl}/auth`;

const kExtensionOauthRedirectDev =
  'https://cdbellnanklnpiabpcjbajcddpeoccli.chromiumapp.org/callback';
const kExtensionOauthRedirectProd =
  'https://fggkaccpbmombhnjkjokndojfgagejfb.chromiumapp.org/callback';
export const kExtensionOauthRedirect = isProduction
  ? kExtensionOauthRedirectProd
  : kExtensionOauthRedirectDev;

export const kIntegrationRequest = 'https://tactiq.typeform.com/to/P4aYm23a';
export const kSlackHowToVideo = 'https://youtu.be/r3TyC5MhzQM';

// @deprecated use kSettingsTags instead
export const kLegacyTags = '/tags';
// @deprecated use kSettingsLabels instead
export const kLegacyLabels = '/labels';
export const kTranscripts = '/transcripts';
export const kTranscriptsUpload = '/transcripts/upload';
export const kSharedWithMe = '/shared-with-me';
export const kTranscriptsRedirect = '/#/transcripts';
export const kTeamTranscripts = '/team-transcripts';
export const kSpace = '/space/:id';
export const workflowList = '/workflows';
export const workflowListWithModalOpen = '/workflows/explore';
export const workflowItem = '/workflows/:id';
export const workflowItemActivity = '/workflows/:id/activity';
export const workflowItemExecution = '/workflows/:id/activity/:executionId';
export const kTranscriptsArchive = '/transcripts-archive';
export const kMeeting = '/transcripts/:id';
export const kMeetingShare = '/transcripts/:id/share';
export const kSharedMeeting = '/m/:id/:key';
export const kSharedRestrictedMeeting = '/ma/:id/:key';
export const kMeetingShareIntegration = '/transcripts/:id/share/:integrationId';
// @deprecated use kSettings instead
export const kLegacySettings = '/settings';
export const kSettings = '/settings/:category';
export const kSettingsGeneral = '/settings/general';
export const kSettingsTags = '/settings/tags';
export const kSettingsAutoCorrect = '/settings/auto-correct';
export const kSettingsLabels = '/settings/labels';
export const kSettingsAccount = '/settings/account';
export const kSettingsTeam = '/settings/team';
export const settingsDebug = '/settings/debug';
// @deprecated use kSettingsAccount instead
export const kLegacyAccount = '/account';
export const kInvite = '/invite';
// @deprecated use kSettingsAutoReplace instead
export const kLegacyAutoReplace = '/autocorrect';
// @deprecated use kSettingsAutoHighlight instead
export const kLegacyAutoHighlight = '/autohighlight';
export const kTeam = '/team';
export const kTeamInvite = '/team/invite';
export const kReporting = '/reporting';
export const kReportingOwnMeetings = '/reporting/own-meetings';

// oauth access grant flow ( for now just 'authenticate zapier to use tactiq api' )
export const kOauthConsentScreen = '/oauth/consent';

// token generator and redirect for desktop app
export const kAuthDesktop = '/desktop-app-auth';
